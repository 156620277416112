import { ReactComponent as LogoSVG } from '../../assets/images/brovnie_logo.svg';
import { ReactComponent as Beker } from '../../assets/images/beker.svg';

export const Logo = () => {
  return (
    <div className="h-14">
      <a href="/" className="flex max-h-[20px] items-center mt-5">
        <LogoSVG />
      </a>
    </div>
  );
};
