import React from 'react';
import { doSignOut } from '../../firebase/auth';

export const Logout = () => {
  return (
    <div>
      <button
        onClick={() => {
          doSignOut();
        }}
        className="border border-2 border-persianBlue-900 bg-gradient-to-b from-ebony to-persianBlue-200  px-3 py-2 rounded-md text-ms font-bold"
      >
        Logout
        <div className=""></div>
      </button>
    </div>
  );
};
