import { useState } from 'react';
import { db } from '../../firebase/config';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  id: string;
  showOnPage: boolean;
};

export const TableItem = (props: Props) => {
  const [isVisible, setIsVisible] = useState(props.showOnPage);
  const navigate = useNavigate();
  const removeFromView = async () => {
    const collectionRef = doc(db, 'splines', props.id);
    await updateDoc(collectionRef, {
      showOnPage: !isVisible,
    });
    setIsVisible((prev) => !prev);
  };

  return (
    <div className="grid grid-cols-5 gap-4 py-3 border-b border-1 border-persianBlue-900">
      <a href={`/models/${props.id}`} className="col-span-3 text-blueberry">
        {props.title}
      </a>

      <p className="col-start-4 text-center text-lg font-extrabold">
        {isVisible ? 'Yes' : 'No'}
      </p>
      <div className="col-start-5 flex gap-5">
        <button onClick={() => navigate(`/models/${props.id}/edit`)}>
          Edit
        </button>
        <button
          onClick={removeFromView}
          className="border border-1 rounded-md px-2 border-red-500 bg-red-700"
        >
          {isVisible ? 'Disable' : 'Enable'}
        </button>
      </div>
    </div>
  );
};
