import React, { useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../firebase/config';
import { DbItem } from '../../features/types';

export const Card = (props: DbItem) => {
  const [imageUrl, setImageUrl] = useState('');
  if (!props.showOnPage) return null;

  if (props.image) {
    const imgRef = ref(storage, `splines/${props.image}`);
    let image = getDownloadURL(imgRef).then((url) => {
      setImageUrl(url);
      return;
    });
  }

  return (
    <div className=" border border-2 border-blueGem-900 rounded-md  bg-gradient-to-b from-ebony to-blueGem-100  relative max-h-80 min-h-80 sm:min-h-0 hover:shadow-[0_20px_50px_rgba(39,_24,_189,_0.7)] ease-in duration-300">
      <a
        href={'/models/' + props.id}
        className="aboslute w-full h-full z-10 flex flex-col items-center justify-center"
      >
        <img alt="" src={imageUrl} className="max-h-[80%] " />
        <div className="pb-5 max-h-[20%]">
          <p className="text-center text-3xl">{props.title}</p>
        </div>
      </a>
    </div>
  );
};
