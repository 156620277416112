import React from 'react';
import { Navigation } from '../Nav/Navigation';

type Props = {
  children: string | JSX.Element | JSX.Element[];
  hideHeader?: boolean;
};

export const Body = ({ children, hideHeader }: Props) => {
  return (
    <div className="flex flex-col min-h-screen container px-5 sm:mx-auto">
      <Navigation />
      {hideHeader && <header>Test</header>}
      <main className="flex-1 relative">{children}</main>
      <footer className="text-center pb-5">
        Copyright{' '}
        <a href="https://brovnie.be" className="text-blueberry">
          brovnie
        </a>{' '}
        2024
      </footer>
    </div>
  );
};
