import { LoginForm } from '../../features/Login';
import { Body } from '../../components/Layout/Body';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const Login = () => {
  const userLoggedIn = useAuth()?.userLoggedIn;
  const navigate = useNavigate();

  useEffect(() => {
    if (userLoggedIn) {
      navigate('/');
    }
  }, [userLoggedIn]);

  return (
    <Body>
      <div>
        <p className="text-7xl font-bold">Login</p>
        <LoginForm />
      </div>
    </Body>
  );
};
