import React from 'react';
import { Body } from '../../components/Layout/Body';

export const Page404 = () => {
  return (
    <Body>
      <div className="absolute  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-[72px]">
        <p className="text-center text-7xl pb-4">404 - Item not found</p>
        <p className="text-center text-2xl">
          Go back to{' '}
          <a href="/" className="text-blueberry ">
            gallery
          </a>
        </p>
      </div>
    </Body>
  );
};
