import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/UI/Input';
import { Button } from '../../components/UI/Button';
import { FormEvent, useEffect, useState } from 'react';
import { doc, DocumentData, updateDoc } from 'firebase/firestore';
import { getItem } from '../items';
import { Form } from '../../components/Layout/Form';
import { db } from '../../firebase/config';

interface EditItemProps {
  id: string | undefined;
}

export const EditItem: React.FC<EditItemProps> = ({ id }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(null);
  const [itemSource, setItemSource] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const data = await getItem(id);
        setTitle(data?.title);
        setItemSource(
          data?.type === 'spline' ? data.spline_url : data?.codepen_id
        );
        setItemType(data?.type);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id]);

  if (!title && !itemSource && !itemType && !isLoading) {
    navigate('/404');
    return null;
  }
  const update = async (e: any) => {
    e.preventDefault();
    if (id) {
      console.warn('here');
      const itemDocRef = doc(db, 'splines', id);

      try {
        await updateDoc(itemDocRef, {
          title: title,
          spline_url: itemType === 'spline' ? itemSource : '',
          codepen_id: itemType === 'codepen' ? itemSource : '',
        });
        console.log('Document successfully updated!');
        navigate('/dashboard');
      } catch (error) {
        console.warn('error', error);
      }
    }
  };

  return (
    <Form onSubmit={update}>
      <Input
        type="text"
        placeholder="Enter title"
        value={title ? title : ''}
        name="title"
        onChange={(e) => setTitle(e.target.value)}
      />
      <Input
        type="text"
        value={itemSource ? itemSource : ''}
        placeholder={
          itemType === 'spline' ? 'Enter spline url' : 'Enter codepen id'
        }
        name="url_or_id"
        onChange={(e) => setItemSource(e.target.value)}
      />
      <div className="flex-1 flex flew-row items-center justify-between gap-auto">
        <a
          href="/dashboard"
          className="border border-2 border-persianBlue-900 text-blueberry px-5 py-3 rounded-md"
        >
          Cancel
        </a>
        <Button title="Confirm" type="submit" className="mr-0" />
      </div>
    </Form>
  );
};
