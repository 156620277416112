import { useEffect, useMemo, useState } from 'react';
import { DbItem } from '../../features/types';
import { getItems } from '../../features/items';
import { TableItem } from '../../components/Layout/TableItem';
import { Body } from '../../components/Layout/Body';

export const Dashboard = () => {
  const [data, setData] = useState<DbItem[]>([]);

  useEffect(() => {
    getItems()
      .then((splines) => {
        setData(splines);
      })
      .catch((error) => {
        console.error('Error fetching items:', error);
      });
  }, []);

  return (
    <Body>
      <p className="text-7xl font-bold">Dashboard</p>
      <div className="grid grid-cols-5 gap-4 mt-6 border-b-2 border-blueberry pb-1">
        <p className="col-span-3 font-bold text-2xl">Title</p>
        <p className="col-start-4 text-center font-bold text-2xl">Is Visible</p>
      </div>
      <>
        {data.map((item) => (
          <TableItem
            key={item.id}
            id={item.id}
            title={item.title}
            showOnPage={item.showOnPage}
          />
        ))}
      </>
    </Body>
  );
};
