import React from 'react';

type Props = {
  type: 'submit';
  title: string;
  className?: string;
};

export const Button = (props: Props) => {
  return (
    <button
      type={props.type}
      className={
        ` ml-0 mr-auto bg-blueGem-900 py-3 inline-block max-w-fit px-5 rounded-md border border-blueberry border-1  hover:shadow-[0_20px_50px_rgba(39,_24,_189,_0.7)] ` +
        props.className
      }
    >
      {props.title}
    </button>
  );
};
