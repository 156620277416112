import React from 'react';
import { Logo } from './Logo';
import { Logout } from './Logout';
import { useAuth } from '../../context/authContext';

export const Navigation = () => {
  const userLoggedIn = useAuth()?.userLoggedIn;

  return (
    <div>
      <nav className="flex flex-row justify-between items-center py-2">
        <Logo />
        {userLoggedIn && <Logout />}
      </nav>
    </div>
  );
};
