import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from './config';
import { useNavigate } from 'react-router-dom';

export const doCreateUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const doSignInWithEmailAndPassword = (
  email: string,
  password: string
) => {
  signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      const navigate = useNavigate();
      navigate('/');
    })
    .catch((error) => {
      switch (error.code) {
        case 'auth/invalid-credential':
          return 'You have entered an invalid username or password';
          break;
        case 'auth/too-many-requests':
          return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.';
          break;
        default:
          return 'Error has occured';
      }
    });
};

export const doSignOut = () => {
  return auth.signOut();
};
