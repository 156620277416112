import { useState } from 'react';
import { doSignInWithEmailAndPassword } from '../../firebase/auth';
import { Input } from '../../components/UI/Input';
import { Button } from '../../components/UI/Button';

export const LoginForm = () => {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [error, setError] = useState('');

  const login = async (e: any) => {
    e.preventDefault();
    if (!isSigningIn) {
      setIsSigningIn(true);
      const formData = new FormData(e.target);
      const email = formData.get('email')?.toString();
      const password = formData.get('password')?.toString();

      if (email && password) {
        await doSignInWithEmailAndPassword(email, password);
      } else {
        setIsSigningIn(false);
        setError('Email or password is empty');
      }
    }
    setIsSigningIn(false);
    e.target.reset();
  };

  return (
    <form onSubmit={login} className="flex flex-col max-w-[500px] gap-5 mt-8">
      {error && <p>{error}</p>}
      <Input type="email" placeholder="enter email" name="email" />
      <Input type="password" placeholder="enter password" name="password" />
      <Button title="Log in" type="submit" />
    </form>
  );
};
