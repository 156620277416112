import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db, storage } from '../../firebase/config';
import { ref, uploadBytes } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/UI/Input';
import { useState } from 'react';
import { Button } from '../../components/UI/Button';
import { Form } from '../../components/Layout/Form';

export const AddItem = () => {
  const navigate = useNavigate();
  const [type, setType] = useState('spline');
  const addItem = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const splineUrl = type === 'spline' ? formData.get('url_or_id') : '';
    const codepenId = type === 'codepen' ? formData.get('url_or_id') : '';
    const title = formData.get('title');
    const image = e.target.file.files[0];
    const imageName = uuidv4();
    const imageRef = ref(storage, `splines/${imageName}`);
    uploadBytes(imageRef, image);
    try {
      await addDoc(collection(db, 'splines'), {
        type,
        title,
        spline_url: splineUrl,
        codepen_id: codepenId,
        image: imageName,
        showOnPage: true,
        created_at: new Date().getDate(),
      });
    } catch (err) {
      alert(err);
    }
    e.target.reset();
    navigate('/');
  };

  return (
    <Form onSubmit={addItem}>
      <select
        name="type"
        onChange={(e) => setType(e.target.value)}
        className="px-3 py-3 rounded-lg bg-blueGem-100 placeholder-white border border-1 border-blueberry focus:shadow-[0_20px_50px_rgba(39,_24,_189,_0.7)]"
      >
        <option value="spline">Spline</option>
        <option value="codepen"> Codepen </option>
      </select>
      <Input type="text" placeholder="Enter title" name="title" />
      <Input
        type="text"
        placeholder={type === 'spline' ? 'Enter url' : 'Enter codepen id'}
        name="url_or_id"
      />
      <input
        type="file"
        name="file"
        className="text-sm text-grey-500
            file:mr-5 file:py-2 file:px-6
            file:rounded-md file:border-1
            file:border-blueberry
            file:text-md file:font-bold
            file:bg-gradient-to-b from-ebony to-blueGem-900
             file:text-white
            hover:file:cursor-pointer 
            hover:file:shadow-[0_20px_50px_rgba(39,_24,_189,_0.7)]
            "
      />
      <Button title="Add" type="submit" />
    </Form>
  );
};
