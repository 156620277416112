import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { DbItem } from './types';

export const getItems = () => {
  return new Promise<DbItem[]>((resolve, reject) => {
    let collectionRef = collection(db, 'splines');
    let item = [] as DbItem[];
    let orderedQuery = query(collectionRef, orderBy('created_at', 'desc'));
    onSnapshot(
      orderedQuery,
      (snapshot) => {
        item = snapshot.docs.map((doc: any) =>
          Object.assign({ id: doc.id }, doc.data())
        );
        resolve(item);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const getItem = async (id: string) => {
  const splineRef = doc(db, 'splines', id);
  const spline = await getDoc(splineRef);
  return spline.data();
};
