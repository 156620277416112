import React from 'react';

type Props = {
  type: 'text' | 'password' | 'email';
  name: string;
  placeholder: string;
  value?: string;
  onChange?: (value: any) => void;
};

export const Input = (props: Props) => {
  return (
    <input
      className="px-3 py-3 rounded-lg bg-blueGem-100 placeholder-white border border-2 border-blueberry focus:shadow-[0_20px_50px_rgba(39,_24,_189,_0.7)] "
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      defaultValue={props.value}
      onChange={props.onChange}
    />
  );
};
