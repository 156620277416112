import { Body } from '../../components/Layout/Body';
import { AddItem } from '../../features/SingleItem/AddItem';

export const CreateItem = () => {
  return (
    <Body>
      <div className="h-40 flex items-end justify-start py-6">
        <p className="text-5xl text-left font-bold ">Add Spline</p>
      </div>
      <AddItem />
    </Body>
  );
};
