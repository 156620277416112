import React, {
  useContext,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase/config';

type AuthContextType = {
  userLoggedIn: boolean;
  currentUser: User | null;
  setCurrentUser: Dispatch<SetStateAction<null>>;
};
type ContextProp = {
  children: React.ReactNode;
};

const AuthContext = React.createContext<AuthContextType | null>(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: ContextProp) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  async function initializeUser(user: any) {
    if (user) {
      console.warn('conetext');
      setCurrentUser({ ...user });
      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setUserLoggedIn(false);
    }

    setLoading(false);
  }

  const value = {
    userLoggedIn,
    currentUser,
    setCurrentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
