import { useEffect, useState } from 'react';
import { Body } from './components/Layout/Body';
import { DbItem } from './features/types';
import { getItems } from './features/items';
import { Card } from './components/Layout/Card';

function App() {
  const [data, setData] = useState<DbItem[]>([]);

  useEffect(() => {
    getItems()
      .then((splines) => {
        setData(splines);
      })
      .catch((error) => {
        console.error('Error fetching splines:', error);
      });
  }, []);

  return (
    <Body>
      <div className="h-60 flex flex-col items-start justify-center">
        <h1 className="text-7xl font-bold ">Creative Lab</h1>
        <p className="text-blueberry text-3xl pt-2">
          Collection of 3d models and css creations
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-5">
        {data.map((item) => (
          <Card
            key={item.id}
            id={item.id}
            image={item.image}
            showOnPage={item.showOnPage}
            title={item.title}
            created_at={item.created_at}
            type={item.type}
          />
        ))}
      </div>
    </Body>
  );
}

export default App;
