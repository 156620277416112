import { Body } from '../../components/Layout/Body';
import { useParams } from 'react-router-dom';
import { EditItem } from '../../features/SingleItem/EditItem';

export const Edit = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <Body>
      <div className="h-40 flex items-end justify-start py-6">
        <p className="text-5xl text-left font-bold ">Edit Spline</p>
      </div>
      <EditItem id={id} />
    </Body>
  );
};
