import React, { Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getItem } from '../../features/items';
import { DocumentData } from 'firebase/firestore';
import { Body } from '../../components/Layout/Body';
import Codepen from 'ts-react-codepen-embed';

const Spline = React.lazy(() => import('@splinetool/react-spline'));

export const SingleItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [splineData, setSplineData] = useState<DocumentData | null | undefined>(
    null
  );
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const data = await getItem(id);
        setSplineData(data);
      }
    };
    fetchData();
  }, [id]);
  if (!splineData) {
    navigate('/404');
    return null;
  }

  return (
    <Body>
      <div className="flex items-center font-md pb-16 pt-20 relative">
        <a
          href={'/'}
          className=" text-blueberry flex flex-row items-center absolute l-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
          <p>Back</p>
        </a>
        <h2 className="text-7xl font-bold flex-1 text-center">
          {splineData.title}
        </h2>
      </div>
      <div>
        {splineData.type === 'spline' && (
          <div className="flex justify-center">
            <SingleSpline url={splineData.spline_url as string} />
          </div>
        )}
        {splineData.type === 'codepen' && (
          <Codepen
            hash={splineData.codepen_id}
            user="brovnie_dev"
            defaultTab="result"
            height={525}
          />
        )}
      </div>
    </Body>
  );
};

interface SingleSplineProps {
  url: string;
}

export const SingleSpline: React.FC<SingleSplineProps> = ({ url }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Suspense>
      <Spline
        className="max-w-fit"
        onLoad={() => setIsLoading(true)}
        scene={url}
      />
    </Suspense>
  );
};
