import React from 'react';

type FromProps = {
  onSubmit: (e: any) => void;
  children: React.ReactNode;
};

export const Form = (props: FromProps) => {
  return (
    <form
      onSubmit={props.onSubmit}
      className="flex flex-col gap-5 max-w-[500px]"
    >
      {props.children}
    </form>
  );
};
