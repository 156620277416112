import { Routes, Route } from 'react-router-dom';
import { Dashboard, Login, Page404, SingleItem } from '../pages';
import PrivateRoutes from './PrivateRoutes';
import { PublicOnlyRoutes } from './PublicOnlyRoutes';
import App from '../App';
import { CreateItem } from '../pages/CreateItem/CreateItem';
import { Edit } from '../pages/EditeItem/Edit';

export const SplinesRouter = () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route element={<Dashboard />} path="/dashboard" />
        <Route element={<CreateItem />} path="/add" />
      </Route>
      <Route element={<PublicOnlyRoutes />}>
        <Route element={<Login />} path="/login" />
      </Route>
      <Route element={<App />} path="/" />
      <Route element={<SingleItem />} path="/models/:id" />
      <Route element={<Edit />} path="/models/:id/edit" />
      <Route element={<Page404 />} path="*" />
    </Routes>
  );
};
